import React from 'react'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'

type Props = {}

const Pearls = (props: Props) => {
  return (
    <PageWrapper >
        <UserBalance/>
    </PageWrapper>
  )
}

export default Pearls