import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'
import tg from 'utils/tg'

interface Props extends HTMLMotionProps<"button"> {
  sandBtn?:true
}

const Button = (props: Props) => {
  return (
    <motion.button
    onPointerDown={(e) => {
        tg.HapticFeedback.selectionChanged()
        if(props.onPointerDown) props.onPointerDown(e)
    }
    }
    whileTap={{scale:0.95}}
    {...props}
    className={`${props.sandBtn ? 'sand-button' : ''} ${props.className ?? ''}`}
    >
        {props.children}
    </motion.button>
  )
}

export default Button