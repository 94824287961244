import Earn from "pages/Earn/Earn";
import Entry from "pages/Entry/Entry";
import Friends from "pages/Friends/Friends";
import Pearls from "pages/Pearls/Pearls";
import Tap from "pages/Tap/Tap";
import { RouteObject } from "react-router-dom";
import Icon from "UI/Icon";

type Route = {
  route:RouteObject
  titleKey?:string
  navBarIcon?:JSX.Element
}

const routes:Route[] = [
    {
      route:{
        path: "/",
        element: <Entry />,
      },
    },
    {
      route:{
        path: "/tap",
        element: <Tap />,
      },
      navBarIcon:<Icon icon="navigationTap"/>,
      titleKey:'Tap',

    },
    {
      route:{
        path: "/earn",
        element: <Earn />,
      },
      navBarIcon:<Icon icon="navigationEarn"/>,
      titleKey:'Earn',

    },
    {
      route:{
        path: "/friends",
        element: <Friends />,
      },
      navBarIcon:<Icon icon="navigationFriends"/>,
      titleKey:'Friends',

    },
    {
      route:{
        path: "/pearls",
        element: <Pearls />,
      },
      navBarIcon:<Icon icon="navigationPearls"/>,
      titleKey:'Pearls',

    },
  ]
  export default routes