import React, { useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './Earn.css'
import EarnNavigation from './components/EarnNavigation'
import EarnSections, { EarnSectionType } from './earn-sections'
import { AnimatePresence } from 'framer-motion'
import EarnSection from './components/EarnSection'

const Earn = () => {
  const [section, setsection] = useState<EarnSectionType>(EarnSections[0])
  const [direction, setdirection] = useState(0)

  const goToPage = (sec:EarnSectionType) => {
    const currentIndex = EarnSections.findIndex(({title}) => title === section.title)
    const nextIndex = EarnSections.findIndex(({title}) => title === sec.title)
    setdirection(currentIndex > nextIndex ? -1 : 1)
    setsection(sec)
  }

  return (
    <PageWrapper className='earn-page'>
      <div className="earn-page-container">
          <AnimatePresence mode='sync' custom={direction} initial={false}>
            <EarnSection custom={direction} key={section.title}>
              {section.section}
            </EarnSection>
          </AnimatePresence>
      </div>
        <EarnNavigation setSection={goToPage} current={section}/>
    <div className='page-shadow'/>
    </PageWrapper>
  )
}

export default Earn