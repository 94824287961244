import React from 'react'
import './Tap.css'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import Icon from 'UI/Icon'
import Button from 'UI/Button'
import energyImg from 'assets/images/energy-icon.png'
import { motion } from 'framer-motion'
import Oyster from './components/Oyster'
type Props = {}

const Tap = (props: Props) => {
  return (
    <PageWrapper className='tap-page'>
      <UserBalance/>
      <div className="income">
        <div>
          <Icon icon='minCoin'/>
          <p>1 <span>income / hour</span></p>
        </div>
        <div>
          <Icon icon='tapIcon'/>
          <p>1 <span>income / tap</span></p>
        </div>
      </div>
      <div className="about-token-container">
      <Button className="about-token">
        <Icon icon='aboutIcon'/>
        <p>About token</p>
      </Button>
      </div>
      <Oyster/>
      <div className="energy-container">
        <div className="energy-value">
          <img src={energyImg} alt="" />
          <p><span>75</span> / 125</p>
        </div>
        <div className="energy-progress-container">
          <div className="energy-progress-wrapper">
            <div className="energy-progress-line-container">
              <div className="energy-progress-line" style={{width:'45%'}}/>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default Tap