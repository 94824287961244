import React, { useContext } from 'react'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import Icon from './Icon'

type Props = {}

const UserBalance = (props: Props) => {
    const {user} = useContext(appContext)
    console.log("🚀 ~ UserBalance ~ user:", user)
  return (
    <div className='user-balance-container'>
        <div className="user-profile">
            <img src={user?.avatar} alt="" />
            <p>{user?.first_name}</p>
        </div>
        <div className="user-balance">
            <p>{formatNumber(user?.balance)} <span>t</span></p>
            <Icon icon='coin'/>
        </div>
    </div>
  )
}

export default UserBalance