import React, { useContext } from "react";
import EarnSection from "../components/EarnSection";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import energyImg from "assets/images/energy-booster-image.jpg";
import TimerText from "UI/TimerText";
import Button from "UI/Button";

type Props = {};

const EarnBoosters = (props: Props) => {
  const { user ,setModal} = useContext(appContext);

  return (
    <div className="earn-boosters">
      <ul className="earn-boosters-stats">
        <li>
          <Icon icon="coin" />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>balance</span>
          </div>
        </li>
        <li>
          <Icon icon="energy" />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>energy bank</span>
          </div>
        </li>
        <li>
          <Icon icon="tapIcon" />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>income / tap</span>
          </div>
        </li>
      </ul>
      <h1>Buy upgrades and <br />
      increase your income</h1>

      <div className="boosters-list-wrapper">
        <div className="divider">
          <hr /> <h3>Free daily boosters</h3>  <hr />
        </div>
        <ul className="boosters-list">
          <li className="boosters-list-item">
            <img src={energyImg} alt="" style={{filter:'grayscale(1)'}}/>
            <div className="boosters-list-item-desc">
              <h4>A full supply <br />
              of energy</h4>
              <Button className="boosters-list-item-btn">
                <TimerText time={36000}/>
              </Button>
            </div>
          </li>
          <li className="boosters-list-item">
            <img src={energyImg} alt="" />
            <div className="boosters-list-item-desc">
              <h4>A full supply <br />
              of energy</h4>
              <Button className="boosters-list-item-btn active" onClick={() => setModal({content:<GetEnergyModal/>})}>
                <p>Get that (2/5)</p>
              </Button>
            </div>
          </li>
        </ul>
      </div>

      <div className="boosters-list-wrapper">
        <div className="divider">
          <hr /> <h3>Boosters</h3>  <hr />
        </div>
        <ul className="boosters-list">
          <li className="boosters-list-item">
            <div className="energy-booster-img">
              <Icon icon="bigEnergyIcon"/>
            </div>
            <div className="boosters-list-item-desc">
              <h4>Buy 125 points <br />
              of energy</h4>
              <div className="boosters-list-item-btn-container">
              <Button className="boosters-list-item-btn active">
                <p>Get that</p>
              </Button>
              <div className="boosters-list-item-cost">
                <Icon icon="coin"/>
                <span>50t</span>
              </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
  );
};

const GetEnergyModal = () => {
  const { setModal } = useContext(appContext);
  return (
    <>
      <h2 style={{textAlign:'center',fontWeight:500}}>
      You get a full <br />
  supply of energy
      </h2>
      <p style={{textAlign:'center',margin:'5px 0 20px'}}>
        <span className="sand-text">+100 tokens</span> on your balance
      </p>
      <Button
          sandBtn
          className="daily-reward-gain"
          onClick={() => setModal(null)}
        >
          Let’s rock!
        </Button>
    </>
  );
}


export default EarnBoosters;
