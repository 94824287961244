import { createContext, ReactNode, useEffect, useRef, useState } from "react"
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import getLang from "utils/get-lang";
import tg from "utils/tg";
import ModalProps from "types/modal";
const errors = {
    "appError":"It seems that some kind of error has occurred, you need to go back to the bot and send /start",
    "quit":"Back to the bot"
}
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(1)) || ""
export default () => {
    const [user, setUser] = useState<UserData | null>(null)
    const [showGameRules, setshowGameRules] = useState(false)
    const [modal, setModal] = useState<ModalProps | null>(null)
    const interval = useRef<NodeJS.Timer | null>(null)
    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result) setUser(result)
        if(result?.error) {
            tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
                tg.close()
                tg.openTelegramLink(inviteLink)
            })
        }
        
    }

    const AppContextValue:AppContextType = {
        user,
        updateUserInfo,
        setUser,
        showGameRules,
        setshowGameRules,
        modal,setModal
    }


    useEffect(() => {
        updateUserInfo()
    }, [])
    useEffect(() => {
        if(interval.current) clearInterval(interval.current)
        if (!user || !user.energy_seconds) return;

        // const { lang, max_energy, energy_speed } = user;
        const { max_energy, energy_speed } = user;
    
        // Обновляем язык, если он изменился
        // if (lang !== language) setLanguage(lang || 'en');
        
        interval.current = setInterval(() => {
            setUser((prevUser) => {
                if (!prevUser) return prevUser;
    
                const { energy, max_energy } = prevUser;
    
                // Если энергия меньше максимальной, прибавляем
                if (energy < max_energy) {
                    let nextValue = energy + energy_speed;
                    nextValue = nextValue > max_energy ? max_energy : nextValue;
                    return { ...prevUser, energy: nextValue };
                }
    
                return prevUser;
            });
        }, 1000);
    
        return () => {
            if(interval.current) clearInterval(interval.current)
        };
    }, [user])
    return {AppContext,AppContextValue}
}