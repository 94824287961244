import React from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Friends.css";
import gift from "assets/images/gift.png";
type Props = {};

const Friends = (props: Props) => {
  return (
    <PageWrapper>
      <UserBalance />
      <div className="friends-page-info">
        <img src={gift} alt="" />
        <h1>
          <span className="sand-text">Get 20%</span> of your <br />
          friend’s income
        </h1>
      </div>
    </PageWrapper>
  );
};

export default Friends;
