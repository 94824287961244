import { motion } from 'framer-motion';
import { useState, MouseEvent, TouchEvent, PointerEventHandler } from 'react';
import oysterImg from 'assets/images/oyster.png';
import tg from 'utils/tg';

interface PlusOne {
  id: number;
  x: number;
  y: number;
  fontSize: number;
  zIndex:number
}

const Oyster: React.FC = () => {
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [plusOnes, setPlusOnes] = useState<PlusOne[]>([]);

  const handleTilt = (x: number, y: number, width: number, height: number) => {
    const rotateYValue = ((x / width) - 0.5) * 60;
    const rotateXValue = ((y / height) - 0.5) * -60;

    setRotateX(rotateXValue);
    setRotateY(rotateYValue);
  };

  const handleMouseClick:PointerEventHandler<HTMLDivElement> = (e) => {
    tg.HapticFeedback.selectionChanged()
    const { width, height, left, top } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;

    handleTilt(x, y, width, height);
    addPlusOne(width, height);
  };

  // const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
  //   const touch = e.touches[0];
  //   const { width, height, left, top } = e.currentTarget.getBoundingClientRect();
  //   const x = touch.clientX - left;
  //   const y = touch.clientY - top;

  //   handleTilt(x, y, width, height);
  //   addPlusOne(width, height);
  // };

  const addPlusOne = (containerWidth: number, containerHeight: number) => {
    const newPlusOne:PlusOne = {
      id: Date.now(),
      x: Math.random() * containerWidth, // Случайная позиция X
      y: Math.random() * containerHeight, // Случайная позиция Y
      fontSize: Math.random() * 63 + 21,
      zIndex:Math.random() > 0.5 ? 1 : -1

    };
    setPlusOnes((prev) => [...prev, newPlusOne]);

    // Удаляем элемент через 1.5 секунды
    setTimeout(() => {
      setPlusOnes((prev) => prev.filter((plusOne) => plusOne.id !== newPlusOne.id));
    }, 1500);
  };

  const resetTilt = () => {
    setRotateX(0);
    setRotateY(0);
  };

  return (
    <div
      className="oyster-container"
      // onClick={handleMouseClick}
      onPointerDown={handleMouseClick}
      onMouseLeave={resetTilt}
      onTouchEnd={resetTilt}
    >
      <motion.img
        className="oyster"
        src={oysterImg}
        style={{ rotateX, rotateY }}
        animate={{
          rotateX,
          rotateY,
          transition: { type: "spring", stiffness: 300, damping: 20 }
        }}
      />

      {plusOnes.map((plusOne) => (
        <motion.div
          key={plusOne.id}
          initial={{ opacity: 0, y: plusOne.y }}
          animate={{ opacity: [1,0], y: plusOne.y - 50 }} // Движение вверх
          transition={{ duration: 1.5 }} // Медленное исчезновение
          style={{
            position: 'absolute',
            left: plusOne.x,
            top: plusOne.y,
            color: 'white', // Белый цвет текста
            fontSize: `${plusOne.fontSize}px`, // Случайный размер шрифта
            pointerEvents: 'none', // Элемент не будет перехватывать клики
            zIndex:plusOne.zIndex
          }}
        >
          +1
        </motion.div>
      ))}
    </div>
  );
};

export default Oyster;
