import { animate, AnimatePresence, HTMLMotionProps, motion, Variants } from "framer-motion";
import React, { useContext } from "react";
import Button from "./Button";
import Icon from "./Icon";
import appContext from "utils/app-context";

type Props = {};

const Modal = (props: Props) => {
  const { modal, setModal } = useContext(appContext);

  return (
    <AnimatePresence>
      {modal && (
        <motion.div
          {...bgVariants}
          onClick={() => setModal(null)}
          className="modal-bg"
        >
          <motion.div
            {...bodyVariants}
            className="modal-body"
            onClick={(e) => e.stopPropagation()}
          >
            {modal.close && (
              <Button className="modal-close" onClick={() => setModal(null)}>
                <Icon icon="close" />
              </Button>
            )}
            {modal.content}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const bodyVariants: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
    y: -100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: [1,0],
    y: 100,
  },
  
};

const bgVariants: HTMLMotionProps<"div"> = {
  initial: {
    background: "transparent",
    backdropFilter: "blur(0px)",
  },
  animate: {
    background: "#000000b3",
    backdropFilter: "blur(10px)",
  },
  exit: {
    background: "transparent",
    backdropFilter: "blur(0px)",
  },
  transition:{
    // duration:0.4
  }
};

export default Modal;
