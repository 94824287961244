import React, { useContext } from "react";
import EarnSection from "../components/EarnSection";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import Button from "UI/Button";

type Props = {};

const EarnTapping = (props: Props) => {
  const { user } = useContext(appContext);
  return (
    <div className="earn-boosters">
      <ul className="earn-boosters-stats">
        <li>
          <Icon icon="coin" />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>balance</span>
          </div>
        </li>
        <li>
          <Icon icon="minCoin" />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>income / hour</span>
          </div>
        </li>
        <li>
          <Icon icon="tapIcon" />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>income / tap</span>
          </div>
        </li>
      </ul>
      <ul className="boosters-list tapping-goods-list">
        {goods.map(
          ({
            image,
            costFriends,
            costStar,
            perTap,
            title,
            costCoins,
            costTons,
          }) => (
            <li className="boosters-list-item tapping-goods-list-item">
              <img src={image} alt="" />
              <div className="boosters-list-item-desc tapping-good-desc">
                <h4>{title}</h4>
                <div className="per-tap">
                  <Icon icon="coin" />
                  <p>
                    +{perTap}t<span>/tap</span>
                  </p>
                </div>
                <p className="tapping-good-cost-text">
                  This boost will cost you one of —
                </p>
                <ul className="cost-variants">
                  <li>
                    <Icon icon="tgStar" />
                    <span>{costStar}</span>
                  </li>
                  <li>
                    <Icon icon="person" />
                    <span>{costFriends}</span>
                  </li>
                  {costCoins && (
                    <li>
                      <Icon icon="coin" />
                      <span>{costCoins / 1000}k</span>
                    </li>
                  )}
                  {costTons && (
                    <li>
                      <Icon icon="ton" />
                      <span>{costTons}</span>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

const goods = [
  {
    title: "Flowers",
    perTap: 1,
    image: "img/goods/1.png",
    costStar: 30,
    costFriends: 5,
    costCoins: 1000,
  },
  {
    title: "Underware",
    perTap: 2,
    image: "img/goods/2.png",
    costStar: 50,
    costFriends: 15,
    costCoins: 3000,
  },
  {
    title: "Porn-site",
    perTap: 5,
    image: "img/goods/3.png",
    costStar: 75,
    costFriends: 25,
    costCoins: 10000,
  },
  {
    title: "Private",
    perTap: 10,
    image: "img/goods/1.png",
    costStar: 300,
    costFriends: 50,
    costTons: 1,
  },
];

const BoostModal = ({ good: { title } }: { good: (typeof goods)[0] }) => {
  return (
    <>
      <h2 style={{ textAlign: "center", fontWeight: 500 }}>
        Tapping boost <br />
        <span className="sand-text">«{title}»</span>
      </h2>
      <p style={{ textAlign: "center", margin: "5px 0 20px" }}>
        If you want to buy this tapping boost, you can do it in three ways.
        Choose yours!
      </p>
      <Button sandBtn className="daily-reward-gain">
        Continue
      </Button>
    </>
  );
};

export default EarnTapping;
